@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(172, 35, 0, 0.42);
  }
  70% {
    -webkit-box-shadow: 0 0 0 8px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(172, 35, 0, 0.42);
    box-shadow: 0 0 0 0 rgba(172, 35, 0, 0.42);
  }
  70% {
    -moz-box-shadow: 0 0 0 8px rgba(182, 6, 0, 0);
    box-shadow: 0 0 0 8px rgba(204, 95, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(240, 93, 93, 0);
    box-shadow: 0 0 0 0 rgba(240, 93, 93, 0);
  }
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}
@-webkit-keyframes pulse-success {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(2, 175, 11, 0.42);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(9, 136, 4, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 131, 50, 0);
  }
}
@keyframes pulse-success {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(2, 175, 11, 0.42);
    box-shadow: 0 0 0 0 rgba(2, 175, 11, 0.42);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(9, 136, 4, 0);
    box-shadow: 0 0 0 10px rgba(9, 136, 4, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 131, 50, 0);
    box-shadow: 0 0 0 0 rgba(0, 131, 50, 0);
  }
}
.pulse-success {
  -webkit-animation-name: pulse-success;
  animation-name: pulse-success;
  -webkit-animation-duration: 1500ms;
  animation-duration: 1500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: 1;
}
.rotate {
  transform: rotate(-180deg);
  transition: transform 1s ease;
}
.un-rotate {
  transform: rotate(-360deg);
  transition: transform 1s ease;
}
.fullRotate {
  transform: rotate(360deg);
  transition: transform 1s ease;
}

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu
  > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}
.ps__rail-y {
  opacity: 0.6;
  z-index: 1021;
}
.ps__thumb-y {
  width: 8px;
  z-index: 1030;
}

.toast {
  position: absolute;
  top: -130px;
  right: 10px;
  background: #fff;
  box-shadow: 0px 0px 11px 0px #ff9393;
  transition: transform 0.7s;
}

.toast-show {
  transform: translateY(150px);
}
.btn-warning,
.btn-warning:visited {
  background-color: #e04693 !important;
  color: white;
  border-color: #b8266f !important;
}
.btn-warning:hover,
.btn-warning:active,
.btn-warning:focus {
  background-color: #b92871 !important;
  border-color: #8d1350 !important;
  color: white;
  box-shadow: 0 0 0 0.1rem rgb(222 12 119 / 50%);
}
.mask {
  opacity: 0.7;
}
.bg-warning {
  background-color: #f68d00 !important;
}
.bg-success {
  background-color: #29ca17 !important;
}
.bg-danger {
  background-color: #f44336 !important;
}
.btn-user-guide {
  -webkit-animation: btnWiggle 5s infinite;
  -moz-animation: btnWiggle 5s infinite;
  -o-animation: btnWiggle 5s infinite;
  animation: btnWiggle 5s infinite;
}
/* animation */
@-webkit-keyframes btnWiggle {
  0% {
    -webkit-transform: rotate(0deg);
  }
  2% {
    -webkit-transform: rotate(-1deg);
  }
  3.5% {
    -webkit-transform: rotate(1deg);
  }
  5% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@-o-keyframes btnWiggle {
  0% {
    -webkit-transform: rotate(0deg);
  }
  2% {
    -webkit-transform: rotate(-1deg);
  }
  3.5% {
    -webkit-transform: rotate(1deg);
  }
  5% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes btnWiggle {
  0% {
    -webkit-transform: rotate(0deg);
  }
  2% {
    -webkit-transform: rotate(-1deg);
  }
  3.5% {
    -webkit-transform: rotate(1deg);
  }
  5% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

.filter-triaged {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  animation: fadein 2s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
